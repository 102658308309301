<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Отправить пуш</h4>
          </div>
        </md-card-header>

        <ValidationObserver ref="sendPushForm" :slim="true">
          <md-card-content>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-large-size-100">
                <ValidationProvider
                  v-slot="{ errors, reset }"
                  mode="passive"
                  :slim="true"
                  rules="required"
                >
                  <md-field :class="{ error: errors.length }">
                    <ValidationError :errors="errors" />
                    <label>Заголовок</label>
                    <md-input v-model="title" @focus="reset" />
                  </md-field>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors, reset }"
                  mode="passive"
                  :slim="true"
                  rules="required"
                >
                  <md-field :class="{ error: errors.length }">
                    <ValidationError :errors="errors" />
                    <label>Текст</label>
                    <md-textarea v-model="body" @focus="reset" />
                  </md-field>
                </ValidationProvider>
              </div>
            </div>

            <div class="buttons-wrap">
              <md-button class="md-raised md-success" @click="addPush">
                Отправить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      title: null,
      body: null,
    };
  },

  methods: {
    ...mapActions("pushes", ["sendPush"]),

    async addPush() {
      const isValid = await this.$refs.sendPushForm.validate();
      if (!isValid) {
        return;
      }

      let push = new FormData();
      push.append("type_key", "cart");
      push.append("title", this.title);
      push.append("body", this.body);

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.sendPush(push);
      if (success) {
        this.title = null;
        this.body = null;
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}
</style>
